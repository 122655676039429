:root {
  --primary-color: #ff6347;
  --flash_message_width: 35rem;
  --flash_message_height_ratio: 0.3063583815;
}

.flash_message_container {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, -150%);
  z-index: 9999;
  width: var(--flash_message_width);
  height: calc(var(--flash_message_width) * var(--flash_message_height_ratio));
  background-color: var(--primary-color);
  font-size: 16px;
  color: white;
  display: flex;
  transition: transform 0.3s ease-out;

  p {
    color: white;
    margin-top: 0.5em;
    font-size: 0.8em;
  }

  h1 {
    font-size: 2em;
    margin: 0rem;
  }

  .icon_side {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      background-color: rgba(256, 256, 256, 0.24);
      border-radius: 100%;
      padding: 0.5em;

      img {
        width: 6em;
      }
    }
  }

  .text_side {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 1em 1em 0em;
  }

  .background_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .exit_button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5em;
    background-color: transparent;
    width: fit-content;
    padding: 0em;

    img {
      width: 1.25em;
    }
  }

  .exit_button:hover {
    cursor: pointer;
  }
}

.flash_message_container.shown {
  transform: translate(-50%, 0%);
}

@media (max-width: 640px) {
  :root {
    --flash_message_width: 22rem;
  }
  .flash_message_container {
    font-size: 10px;
    height: calc(
      var(--flash_message_width) * calc(var(--flash_message_height_ratio) * 1.5)
    );
    p {
      font-size: 1em;
      margin-top: 0.5em;
    }
    .text_side {
      width: 60%;
    }

    .exit_button img {
      width: 3em;
    }
  }
}
