.product-container {
  display: flex;

  section:nth-of-type(1) {
    width: 50%;
    flex-wrap: wrap;
    display: flex;

    .main_display_img_container {
      width: 100%;
      height: 70vh;
      background-color: #f4d6db;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .previews {
      width: 5rem;
      height: 5rem;
      object-fit: contain;
      margin-top: 0.5rem;
      margin-right: 0.5rem;
    }

    .previews:hover {
      cursor: pointer;
    }
  }

  section:nth-of-type(2) {
    width: 50%;
    margin-left: 2rem;
  }

  .button-outline {
    padding: 0.5rem 1rem;
  }
  .price-rateing-container {
    display: flex;
    margin-top: 0.5rem;
  }
  .description {
    margin-top: 0.5rem;
  }
  h5 {
    margin-top: 0.5rem;
  }
  .guide-text {
    margin-top: 0.5rem;
    color: #ff0042;
  }
  .size-container {
    display: flex;
    margin-top: 0.5rem;

    button:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  .color-input-container {
    display: flex;
    margin-top: 0.5rem;
  }
  .color-input-container div {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    margin-right: 0.5rem;
  }

  .color-input-container div:hover {
    cursor: pointer;
  }
  .selected {
    outline: 2px solid black;
  }

  .white-input {
    border: 1px solid black;
    background-color: #fff;
  }

  .red-input {
    background-color: red;
  }

  .orange-input {
    background-color: orange;
  }

  .purple-input {
    background-color: purple;
  }

  .action-button-container {
    display: flex;
    flex-wrap: wrap;
  }
  .action-button-container button {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    section:nth-of-type(1) {
      width: 100%;
      margin-right: 0rem;
    }
    section:nth-of-type(2) {
      width: 100%;
      margin-top: 0.5rem;
      margin-left: 0rem;
    }
  }
}

.product-container.first-comp-margin {
  margin-top: 16vh;
}
