.product-card-container:hover {
  cursor: pointer;
}

.product-card-container {
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  margin: auto;
  width: 13rem;

  /* Remove if it causes problems */
  height: 27.7425rem;

  .thumbail {
    width: 100%;
    height: 22rem;
    object-fit: cover;
  }

  .logo {
    max-width: 50%;
    max-height: 2.5rem;
    object-fit: cover;
  }

  h6 {
    font-size: 1.12rem;
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: max-content;
  }

  .logo,
  h6,
  p {
    margin: 0.25rem 0.5rem 0rem 0.5rem;
  }
}

/* 2xl by default */
@media (max-width: 640px) {
  /* sm */
  .product-card-container {
    width: 9rem;
    .logo {
      max-width: 8rem;
    }
    h6 {
      font-size: 0.95rem;
    }
  }
  .product-card-container {
    .thumbail {
      height: 14rem;
    }
  }
  .product-card-container:nth-of-type(1) {
    /* margin-right: 0.25rem; */
  }
  .product-card-container:nth-of-type(2) {
    /* margin-left: 0.25rem; */
  }
}
@media (max-width: 768px) {
  .product-card-container {
    .thumbail {
      /* height: 30rem; */
    }
    height: auto;
  }
  /* md */
}
@media (max-width: 1024px) {
  /* lg */
}
@media (max-width: 1280px) {
  /* xl */
}
@media (max-width: 2080px) {
  /* 2xl */
}
@media (min-width: 2080px) {
  /* 3xl */
}
