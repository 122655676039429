.funnel-item-01-container {
  height: min-content;
  font-size: 16px;
  .page_content_wrap {
    width: 100%;
  }
  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .content-image {
    width: 100%;
    height: auto;
  }
  .inner-content-container {
    position: absolute;
    width: 30%;
    height: 100%;
    top: 0px;
    display: flex;
    flex-direction: column;
  }
  .strip {
    width: 50%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 25%;
    background-color: #ff779a;
  }
  .text-content {
    position: absolute;
    width: 100%;
    text-align: center;
    color: black;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 38% auto 0 auto;
  }
  .title-content {
    font-size: 9vw;
    line-height: 8vw;
    font-family: "arkanaregular", sans-serif;

    @media (min-width: 768px) and (max-width: 1024px) {
      /* md - lg*/
      font-size: 7vw;
      line-height: 6vw;
    }
  }
  .action {
    margin: auto;
  }
  .link-item {
    color: black;
  }

  @media (max-width: 1024px) {
    /* .title-content {
      font-size: 60px;
      line-height: 50px;
      font-weight: 700;
    } */
  }

  @media (max-width: 640px) {
    .title-content {
      font-size: 7vw;
      line-height: 7.5vw;
    }

    button,
    .link-item {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      font-weight: 700;
      padding: 0.125rem;
    }
  }

  @media (min-width: 2080px) {
    .title-content {
      font-size: 9vw;
      line-height: 8vw;
    }
  }
}
