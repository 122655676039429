.product-display {
  //Temporrary test value
  width: 100%;
  display: flex;
  flex-direction: column;
  display: grid;
  row-gap: 20px;
  margin: 3rem 0 0 0;
}

.product-display.xl {
  padding: 0 24rem;
}

.product-display.xl,
.product-display.lg {
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.product-display.md {
  grid-template-columns: 50% 50%;
}
.product-display.mobile {
  grid-template-columns: 50% 50%;
  margin: 3rem auto 0 auto;
}

@media (max-width: 200px) {
  .product-display.mobile {
    grid-template-columns: 100%;
  }
}
