.massive-header-container {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    backdrop-filter: blur(50px);
    position: absolute;
    top: 0;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }

  .center-text-container {
    margin: auto;
    padding: 0.5rem 1rem;
    backdrop-filter: blur(50px);
    display: flex;
    border-radius: 2000px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: auto;
      color: black;
    }
  }
}
