.funnel-item-05-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	background-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
}
