.funnel-item-03-container {
  display: flex;
  height: 100vh;
  position: relative;

  img {
    height: 100vh;
    position: absolute;
    z-index: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
  }

  .blur {
    z-index: 1;
    position: absolute;
    filter: blur(10px);
    width: 100%;
    height: 100%;
    top: 0;
  }
  section {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  button {
    margin-top: 1rem;
  }

  h2,
  p,
  button {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .retailer-ui-container {
    z-index: 2;
  }
}
