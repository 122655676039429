.filter-container {
  display: flex;
  flex-direction: column;

  .inner-filter-container {
    display: flex;
    margin: auto;
    margin: auto 30rem;
    width: -webkit-fill-available;
    height: 5rem;

    @media screen and (max-width: 1600px) {
      margin: auto 20rem;
    }

    @media screen and (max-width: 1300px) {
      margin: auto 12rem;
    }

    @media screen and (max-width: 1020px) {
      margin: auto;
    }

    p {
      margin: auto 0.25rem auto auto;
    }
  }

  .extended-filter-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .filter-button {
    margin: auto;
    background-color: transparent;
    padding: 0;
    display: flex;
  }

  .filter-category-toggler {
    margin: auto auto auto 0.25rem;
    width: 2rem;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    margin: auto 1rem;
  }

  .input-field[type="checkbox"] {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.5rem;
  }

  input[type="checkbox"] {
    min-width: 1rem;
    height: 1rem;
    flex: 0;
    box-sizing: border-box;
  }
  input[type="checkbox"]:focus {
    outline: 1px solid #081c29;
  }

  .color-label {
    font-weight: 700;
    margin: auto 0;
  }

  .red {
    color: red;
  }
  .blue {
    color: blue;
  }
  .purple {
    color: purple;
  }
  .orange {
    color: orange;
  }
  .green {
    color: green;
  }
  .black {
    color: black;
  }
  .white {
    color: white;
    font-weight: 700;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000000;
  }
  .yellow {
    color: yellow;
  }
}

.filter-container.retailer-ui-container {
  padding: 0rem 5rem;
}

.filter-container.mobile {
  padding: 0rem 1rem;

  .price_filter {
    width: 100%;
    flex-wrap: wrap;

    .input-container {
      width: 45%;
      margin: 0;
    }

    .input-container:nth-of-type(1) {
      margin: 0 5% 0 0;
    }

    .input-container:nth-of-type(2) {
      margin: 0 0 0 5%;
    }

    button.input-container {
      width: fit-content !important;
      margin: 1rem auto 0 0 !important;
    }
  }

  .color_filter,
  .size_filter {
    width: 100%;
    flex-wrap: wrap;

    .input-container {
      width: 45%;
      margin: 0 auto 0 0;
    }

    .input-label {
      margin: auto 0 auto 0.5rem;
    }

    .input-field {
      margin-left: 0;
    }

    .mob_mt {
      margin-top: 1rem;
    }

    button.input-container {
      width: fit-content !important;
      margin: 1rem auto 0 0 !important;
    }
  }
}

@media (max-width: 768px) {
  .filter-container {
    padding: 2rem 0rem;
    height: auto;

    .inner-filter-container {
      flex-direction: column;
      height: auto;
    }
    .filter-button {
      width: 100%;
    }

    .extended-filter-container {
      height: auto;
      padding: 1rem 1rem 2rem 1rem;
    }
  }
}
