.confirmation-container {
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 3rem;
  }

  h3,
  .context {
    width: 100%;
    text-align: center;
  }

  .context {
    margin-top: 1rem;
  }

  button {
  }
}
