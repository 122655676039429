.funnel-item-02-container {
  display: flex;
  flex-direction: row;
  height: 100vh;

  section {
    width: 50%;
    height: 100%;
    display: flex;
  }
  img {
    width: 50%;
    height: 100%;
    object-fit: cover;
    text-align: left;
    object-position: -3rem 0px;
  }
  p,
  a {
    margin-top: 0.5rem;
  }

  .center-content {
    display: flex;
    flex-direction: column;
    max-width: 85%;
  }

  .nav-link {
    font-weight: 600;
    font-size: 1rem;
  }
}

@media (min-width: 640px) {
  .funnel-item-02-container {
    section {
      .retailer-ui-container {
        padding: 1rem;
      }
    }
    img.retailer-ui-container {
      padding: 0;
    }
  }
}
@media (max-width: 640px) {
  .funnel-item-02-container {
    display: flex;
    flex-direction: column;

    section {
      width: 100%;
      height: auto;
    }
    img {
      padding-left: 2rem;
      padding-right: 0;
      width: 100%;
      margin-top: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .funnel-item-02-container {
    .center-content {
      max-width: 100%;
    }
  }
}
