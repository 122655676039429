.auth-container {
  width: 100%;
  padding: 0rem 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  h4,
  label,
  input[type="text"],
  input[type="password"] {
    width: 100%;
  }
  label {
    margin-top: 1rem;
  }
  input[type="text"],
  input[type="password"] {
    margin-top: 0.5rem;
  }

  .forgot-password {
    width: 100%;
    text-align: right;
    margin-top: 1rem;
  }

  button {
    width: 100%;
    text-align: center;
    margin: auto;
    display: block;
    margin-top: 1rem;
  }

  .alread-have-account {
    margin-top: 1rem;
  }

  .remember-me-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 2rem;
    margin-top: 1rem;
    input[type="checkbox"] {
    }
    p {
      width: 100%;
      margin: 0 auto auto 1rem;
    }
  }

  .error-message {
    margin-top: 0.5rem;
    color: #fc5758;
  }
}
@media (max-width: 1800px) {
  .auth-container {
    width: 100%;
    padding: 0rem 35%;
  }
}
@media (max-width: 1500px) {
  .auth-container {
    width: 100%;
    padding: 0rem 30%;
  }
}
@media (max-width: 1000px) {
  .auth-container {
    width: 100%;
    padding: 0rem 25%;
  }
}
@media (max-width: 640px) {
  .auth-container {
    width: 100%;
    padding: 0;
  }
}
