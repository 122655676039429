.funnel-item-06-container {
  display: flex;
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  section:nth-of-type(1) {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  section:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 2rem;
    padding-right: 2rem;
    h5,
    p {
      margin: auto;
      text-align: center;
      color: white;
    }

    h5 {
      margin-bottom: 0.25rem;
    }
    p {
      margin-top: 0.25rem;
    }
  }
}

.funnel-item-06-container.first-comp-margin {
  margin-top: 16vh;
}
@media (max-width: 640px) {
  .funnel-item-06-container {
    width: 90%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;

    img {
      width: auto !important;
      max-width: 80%;
      max-height: 50vh;
      object-fit: cover;
    }

    section:nth-of-type(1) {
      display: flex;
      justify-content: center;
    }

    section:nth-of-type(1),
    section:nth-of-type(2) {
      width: 100%;
    }

    section:nth-of-type(2) {
      padding: 1rem;
    }
  }
}
