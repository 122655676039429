.contact-support-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  label,
  textarea,
  input {
    width: 100%;
    margin-top: 1rem;
    /* padding: 0.125rem; */
  }

  textarea {
    height: 10vh;
  }

  button,
  p {
    margin-top: 1rem;
  }
}

.contact-support-container.first-comp {
  margin-top: 16vh;
}

@media (min-width: 1400px) {
  .contact-support-container {
    padding: 0 25%;
  }
}
