.password-reset-confirmation-container {
  display: flex;
  flex-direction: column;
  h4 {
    text-align: center;
  }
}

.password-reset-confirmation-container.first-comp-margin {
  margin-top: calc(12vh + 4rem);
}
