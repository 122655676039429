.nav-container {
	width: 100%;

	.nav-icon {
		width: auto;
		height: 3rem;
	}

	.site-logo {
		width: 10rem;
		height: auto;
	}

	.left-nav {
		margin-right: auto;
		display: flex;

		li:not(:first-of-type) {
			margin-left: 1rem;
		}
	}

	.right-nav {
		display: flex;
		margin-left: auto;
		li:not(:last-of-type) {
			margin-right: 1rem;
		}
	}

	.retailer-ui-container {
		margin: auto;
	}

	ul {
		li {
			margin: auto 0;
		}
	}

	.icon-nav {
		position: absolute;
		width: 10rem;
		/* left: 0; */
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		display: flex;
	}

	.nav-link img {
		object-fit: contain;
		margin: auto 0;
	}

	.mobile-nav {
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 0vh;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: #ffe4dc;
		pointer-events: none;
		transform: translateX(10rem);
		opacity: 0;
		-webkit-transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		-moz-transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		-o-transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

		li {
			margin: 0 1rem 1rem auto;
		}

		.nav-link {
			margin-top: 1rem;
		}
	}

	.mobile-nav.expand-mobile-nav {
		overflow-y: scroll;
		width: 100%;
		z-index: 15;
		transform: translateX(0rem);
		opacity: 1;
		background-color: #ffe4dc;
		pointer-events: auto;
	}

	.toggler {
		margin: auto 0rem auto auto;
		height: 3rem;
	}

	.extended-nav-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		object-fit: cover;
	}

	.mobile-extended-nav-icon-container {
		z-index: 1;
		display: flex;
		width: 48%;
		margin-left: auto;
		margin-top: 1rem;
	}

	.mobile-extended-nav-icon-container {
		img {
			width: 2.5rem;
			height: 2.5rem;
		}
	}

	.shoping-icon {
		margin-left: 1rem;
		margin-right: auto;
	}

	.close-extended-nav-icon {
		margin-left: auto;
		margin-right: 1rem;
	}

	.animation-container {
		div {
			display: flex;
			flex-direction: column;

			.mobile-dropdown-link {
				margin-right: 2rem;
			}
		}
	}

	.link-anchor {
		display: flex;
	}
	.dropdown-icon {
		width: 1rem;
	}
	@media (max-width: 640px) {
		.dropdown-icon {
			width: 2rem;
		}
	}

	.nav-link {
		display: flex;
		flex-direction: row;
	}

	.gizmo-container {
		width: 2rem;
	}

	.mobile-nav .nav-link.gizmo-container {
		margin-top: 0rem;
		margin-left: 1rem;
		width: 3rem;
	}
}

nav {
	display: flex;
	height: 12vh;
	width: 100%;
	background-color: #ffe4dc;

	ul {
		display: flex;
		flex-direction: row;
	}
}

.nav-item-container {
	position: relative;
	.extend-dropdown-menu {
		opacity: 1 !important;
		transform: translateY(2rem) !important;
		top: 42% !important;
		max-height: 500000rem !important;
		pointer-events: auto !important;
	}

	.dropdown-container {
		background-color: #ffe4dc;
		padding: 2rem;
		padding-top: 0rem;
		position: absolute;
		top: 5vh;
		left: -2rem;
		height: auto;
		overflow-y: hidden;
		width: max-content;
		pointer-events: none;

		max-height: 0rem;
		transform: translateY(-2rem);
		opacity: 0;

		-webkit-transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
		-moz-transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
		-o-transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, max-height 0.3s ease-in-out;

		flex-direction: column;

		li:not(:first-of-type) {
			margin: 1rem 0rem 0rem 0rem !important;
		}
	}
}

@media (min-width: 640px) {
	.nav-container {
		.logo-nav-container {
			position: absolute;
			left: 50%;
			transform: translatex(-50%);
		}
	}
	li.nav-item-container {
		height: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;

		.link-anchor,
		.dropdown-icon,
		.nav-icon {
			margin-top: auto;
			margin-bottom: auto;
		}
	}
}

@keyframes growDown {
	0% {
		transform: scaleY(0);
	}
	80% {
		transform: scaleY(1.1);
	}
	100% {
		transform: scaleY(1);
	}
}

@media (max-width: 450px) {
	.nav-container {
		.mobile-extended-nav-icon-container {
			margin-right: 0rem;
			padding-right: 0rem;
		}
	}
}
