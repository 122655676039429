.funnel-item-04-container {
  width: 100%;
  display: flex;
  padding-left: 12rem;
  padding-right: 12rem;
  .retailer-ui-container {
    display: flex;
  }
  .display-image {
    width: 70%;
    height: auto;
    object-fit: cover;
    background-color: #ff779a;
  }
  .text-content-container {
    width: 31%;
    margin-left: -1%;
    position: relative;
  }
  .display-image,
  .text-content-container {
    background-color: #ff779a;
    display: flex;
    flex-direction: column;
  }
  .text-center-container {
    margin: auto 0;
    width: 100%;
    padding: 1rem;
  }

  .logo {
    width: 100%;
    height: auto;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  h3,
  p {
    text-align: center;
    width: 100%;
  }

  p {
    margin-top: 2rem;
    font-size: 0.75rem;
  }

  .action-button {
    bottom: 2rem;
    width: 100%;
    text-align: center;
    font-weight: 700;
    background-color: transparent;
    padding: 0rem;
    outline: none;
    display: table-footer-group;
    margin-top: 1rem;
  }
}
@media (max-width: 602px) {
  .funnel-item-04-container {
    padding-left: 0rem;
    padding-right: 0rem;
    max-height: 40vh;
    max-width: auto;

    .text-center-container {
      padding: 0.25rem;
    }

    h3 {
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }

    p {
      font-size: 0.5rem;
      margin-top: 1rem;
    }

    .action-button {
      margin-top: 0.25rem;
      font-size: 0.75rem;
    }
  }
}
@media (max-width: 1024px) and (min-width: 602px) {
  .funnel-item-04-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1824px) {
  .funnel-item-04-container {
    padding-left: 25%;
    padding-right: 25%;
  }
}

@media (min-width: 1424px) and (max-width: 1824px) {
  .funnel-item-04-container {
    padding-left: 16rem;
    padding-right: 16rem;
  }
}

@media (min-width: 1024px) and (max-width: 1424px) {
  .funnel-item-04-container {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
