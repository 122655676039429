.landing-screen-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;

  .video-BG {
    height: 100vh;
    position: absolute;
    z-index: -10;
    top: 0;
    object-fit: cover;
    width: 100%;
  }

  .landing-screen-content {
    height: 88vh;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .landing-content {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: auto 0rem auto auto;
    width: 25rem;
  }
  p {
    margin-top: 1rem;
    color: black;
  }
  button {
    margin-top: 1rem;
  }
  h3,
  button,
  p {
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 640px) {
    /* sm */
  }
  @media (max-width: 768px) {
    /* md */
    .landing-content {
      margin: auto;
      width: auto;
    }

    .cropper {
      width: 218%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      pointer-events: none;
    }
  }
  @media (max-width: 1024px) {
    /* lg */
    p,
    h3,
    button {
      margin: auto;
      text-align: center;
    }
    button {
      margin-top: 1rem;
    }
  }
  @media (max-width: 1280px) {
    /* xl */
  }
  @media (min-width: 1536px) {
    /* 2xl */
    .landing-content {
      margin-right: 8rem;
      width: 33rem;
    }
  }
  @media (max-width: 1536px) {
    /* 2xl */
  }
}
