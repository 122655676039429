.checkout-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  label,
  input {
    width: 100%;
    margin-top: 1rem;
  }

  .category {
    margin-top: 4rem;
  }

  .category:nth-of-type(1) {
    margin-top: 2rem;
  }

  .logo {
    width: 35%;
    margin: 0 auto;
  }

  .shared-row-left-container,
  .shared-row-right-container {
    width: 50%;
  }

  .shared-row-left-container {
    padding-right: 0.5rem;
  }
  .shared-row-right-container {
    padding-left: 0.5rem;
  }

  .shared-row-right-container,
  .shared-row-left-container {
    display: flex;
  }

  .extra_margin {
    margin-top: 2.5rem;
    width: 100%;
  }

  .payment-container {
    width: 100%;
    padding: 0rem 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 4rem;

    .purchase_action {
      margin-top: 2rem;
    }
    #paypal-button-container {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .card-tab {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    img {
      height: 2rem;
      width: fit-content;
    }
    p {
      margin: 0.5rem 0 0 0;
    }
  }

  .cart-container {
    .final-details-row {
      margin-top: 0 !important;
    }

    hr {
      width: 100%;
    }
  }
  .paypal-tab {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    .paypalButton {
      width: 100%;
      height: auto;
      margin: auto 0;
    }
  }

  .success_text {
    color: #256f34;
  }

  .invalid_text {
    color: #c93233;
  }

  .payment_type_selector {
    width: 50%;
    margin-top: 0.25rem;
    border-color: black;
    transition: all 0.2s ease;
  }

  .stripe_mode {
    margin-right: 50%;
    margin-left: 0%;
  }
  .paypal_mode {
    margin-left: 50%;
    margin-right: 0%;
  }
}

@media (max-width: 640px) {
  .checkout-container {
    .logo {
      width: 60%;
      margin: 0 auto;
    }
    .cart-container {
      width: 100vw;
      margin-left: -2rem;
      margin-right: -2rem;
    }
    .payment-container {
      width: 100%;
      padding: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* md */
  .checkout-container {
    .payment-container {
      padding: 0rem 20%;
    }
  }
}

@media (min-width: 1200px) {
  .checkout-container {
    padding: 0rem 20%;
  }
}

@media (min-width: 2000px) {
  .checkout-container {
    padding: 0rem 30%;
  }
}
