.enter_new_password {
  display: flex;
  flex-direction: column;
  h4 {
    text-align: center;
  }

  .narrow-form {
    margin-top: 1rem;
  }
}

.enter_new_password.first-comp-margin {
  margin-top: calc(12vh + 4rem);
}
