footer {
  flex-direction: column;

  .logo_button {
    margin: 0 auto 3.5rem auto;
    width: 30rem;
    background-color: transparent;
    padding: 0rem;
    max-width: -webkit-fill-available;
  }

  .footer_logo {
    width: 30rem;
    background-color: transparent;
    max-width: -webkit-fill-available;
  }

  .footer_links {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .footer_link_item {
    background-color: transparent;
    padding: 0;
    color: black;
    font-size: 1rem;
    width: 50%;
    margin-top: 0.25rem;

    p {
      width: 10rem;
    }

    .text-left {
      text-align: left;
      margin-right: auto;
    }

    .text-right {
      text-align: left;
      margin-left: auto;
    }
  }

  .pl-1 {
    padding-left: 1rem;
  }

  .pr-1 {
    padding-right: 1rem;
  }

  .socials_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 8rem;

    a {
      margin: 0 0.5rem;
    }
  }
}
