.cart-container {
  h1 {
    margin: 0rem auto;
    font-size: 3rem;
  }
  display: flex;
  flex-direction: column;

  width: 100%;
  h4 {
    margin: 0rem auto;
  }

  label {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  hr {
    margin-top: 1rem;
    border-color: black;
  }

  hr:nth-of-type(2) {
    margin-top: 3rem;
  }

  strong {
    font-size: 1.5rem;
  }

  .dsc-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    section:nth-of-type(1) {
      display: flex;
      width: 100%;
      margin-top: 1.5rem;

      label {
        text-align: right;
      }

      label:nth-of-type(1) {
        width: 67%;
        text-align: left;
      }
      label:nth-of-type(2) {
        width: 8%;
      }
      label:nth-of-type(3) {
        width: 10%;
      }
      label:nth-of-type(4) {
        width: 15%;
      }
    }

    section:nth-of-type(2),
    .product_cart_row {
      display: flex;
      width: 100%;
      margin-top: 3rem;
      position: relative;

      img {
        width: 7%;
        height: 0;
        padding-bottom: 10.5%;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        margin-right: 4%;
      }

      .remove_button {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        background-color: #8d8d8d;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 0px solid transparent;

        img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          margin-top: 0.25rem;
        }
      }

      .title_size_container {
        width: 56%;

        p:nth-of-type(2) {
          margin-top: 0.5rem;
        }
      }

      p {
        font-size: 1rem;
      }

      .price_container {
        width: 8%;
        text-align: right;
      }

      .quantity_container {
        width: 10%;
        text-align: right;
      }

      .total_container {
        width: 15%;
        text-align: right;
      }
    }

    .final-details-row {
      display: flex;

      margin-top: 5rem;

      .add_note {
        height: 20vh;
        max-width: 30rem;
      }

      .note {
        width: 100%;
        margin-top: 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
      }

      .prices_container,
      .product_cart_row {
        width: 100%;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

        button,
        h4 {
          margin-left: auto;
          margin-top: 1rem;
          margin-right: 0rem;
        }

        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        div:nth-of-type(1) {
          margin-top: 2rem;
        }

        div {
          margin-top: 1rem;
        }
      }

      textarea {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  .mobile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    button {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    .add-note,
    textarea,
    .detail-container label,
    .detail-container p {
      margin-top: 1rem;
    }
  }

  .gray-background {
    background-color: #f1f1f1;
    padding: 0rem 2rem 2rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .checkout_button {
    margin-top: 1rem;
  }

  .base-info-container {
    display: flex;
    margin-top: 2.5rem;

    section {
      position: relative;
      display: flex;
    }
    .counter {
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: -1rem;
      right: -1rem;
      background-color: #8d8d8d;
      color: white;
      border-radius: 100%;
      text-align: center;
      line-height: 30px;
    }

    .thumbnail_container {
      width: 17%;
      padding-bottom: 25.5%;
      position: relative;
      height: 0;
      display: flex;
      justify-content: center;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .thumbnail {
      width: auto;
      max-width: 100%;
      height: 100%;
      max-height: 15rem;
      position: absolute;
      top: 0;
      object-fit: contain;
    }

    div {
      display: flex;
      flex-direction: column;
      width: 67%;
      padding: 1rem;

      p {
        width: 100%;
      }
    }

    p {
      width: 16%;
      margin: auto 0;
    }
  }

  .base-info-container:nth-of-type(1) {
    margin-top: 2rem;
  }

  .detail-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    label,
    p {
      width: 50%;
    }

    label {
      text-align: left;
    }
    p {
      text-align: right;
    }
  }

  .remove_button {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-color: #8d8d8d;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0px solid transparent;
    margin: 0rem !important;

    img {
      width: 1.5rem;
      height: 1.5rem;
      object-fit: contain;
      margin-top: 0.25rem;
    }
  }
}

@media (max-width: 640px) {
  /* sm */
  .cart-container.retailer-ui-container {
    padding-left: 0rem;
    padding-right: 0rem;

    .remove_button {
      img {
        margin-top: 0rem;
        width: 1.25rem;
      }
    }

    .base-info-container {
      .price_text_mobile {
        text-align: right;
      }
    }
  }

  .cart-container {
    .base-info-container {
      .price_text_mobile {
        text-align: right;
      }
    }
  }
}
